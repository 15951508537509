import React from "react"
import { FaPhone } from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="footer">
      <h4>
        &copy;{new Date().getFullYear()} -{" "}
        <span>Asesoria Guelbenzu Uralde</span>
      </h4>
      <p>
        <FaPhone></FaPhone> Llámanos: 943 273 058
      </p>
      <p>Paseo Duque de Mandas 13, Bajo Dcha, 20012 Donosti</p>
    </footer>
  )
}

export default Footer
